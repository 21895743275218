<div class="p-0 mx-0 pb-5 mb-5">
  <div class="dialog-header d-flex justify-content-between py-3">
    <h1 class="h3 fw-bold">Executed tows (AMANA) Report</h1>
  </div>

  <form
    class="custom-inputs mt-0"
    [formGroup]="filtersForm"
    (ngSubmit)="applyFilter()"
  >
    <div class="row pt-4 pb-0 d-flex">
      <div class="row">
        <!-- Start Date -->
        <div class="col-md-3 custom-inputs mx-0">
          <label for="fromDate">Start Date</label>
          <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
            <input
              matInput
              [matDatepicker]="fromDate"
              placeholder="dd/mm/yyyy"
              formControlName="fromDate"
              id="fromDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- End Date -->
        <div class="col-md-3 custom-inputs mx-0">
          <label for="toDate">End Date</label>
          <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
            <input
              matInput
              [min]="filtersForm?.value?.fromDate"
              placeholder="dd/mm/yyyy"
              [matDatepicker]="toDate"
              formControlName="toDate"
              id="toDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Citation ID -->
        <div class="col-md-3 custom-inputs mx-0">
          <label for="citationId">Citation ID</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <input
              matInput
              (input)="numbersOnlyValidator($event)"
              placeholder="Enter Citation ID"
              formControlName="citationId"
              id="citationId"
            />
          </mat-form-field>
        </div>

        <!-- Vehicle Plate -->
        <div class="col-md-3 custom-inputs mx-0">
          <label for="plateNumber">Vehicle Plate</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <input
              matInput
              placeholder="Enter Vehicle Plate"
              formControlName="plateNumber"
              id="plateNumber"
            />
          </mat-form-field>
        </div>

        <!-- Operation -->
        <div class="col-md-3 custom-inputs mx-0">
          <label for="operation">Operation</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <mat-select
              formControlName="operationId"
              id="operation"
              multiple
              placeholder="Choose operation"
            >
              <mat-option *ngFor="let op of operations" [value]="op?.id">{{
                op?.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Sent Checkbox -->
        <div class="col-md-3 align-self-center custom-inputs mx-0">
          <mat-checkbox formControlName="isSent" id="isSent">Sent</mat-checkbox>
        </div>

        <!-- Buttons -->
        <div class="row d-flex justify-content-end">
          <div class="col-md-5">
            <div class="d-flex row pt-3">
              <button
                [disabled]="loading"
                type="submit"
                class="btn search-btn col-md-5 h4 text-center fw-bold text-white"
              >
                Search
              </button>
              <button
                type="button"
                class="btn filters-btn-clear h4 text-center mx-1 col-md-5 text-white fw-bold"
                (click)="onClearFilter()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <section class="mb-5">
    <div class="mat-elevation-z8 custom-table-card">
      <table class="col-12 rounded bordered-table mt-3">
        <thead class="rounded tableHead">
          <tr class="text-white">
            <th class="py-3 px-4" style="width: 15%">Vehicle plate</th>
            <th class="py-3 px-4" style="width: 10%">Citation ID</th>
            <th class="py-3 px-4" style="width: 15%">Created Date</th>
            <th class="py-3 px-4" style="width: 15%">Operation</th>
            <th class="py-3 px-4" style="width: 15%">Status Message</th>
            <th class="py-3 px-4" style="width: 10%">Is sent</th>
            <th class="py-3 px-4" style="width: 25%">Failed Reason</th>
            <!-- <th class="py-3 px-4" style="width: 15%;">Notification Type</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of dataList; let i = index">
            <tr>
              <td class="fs-6 py-2 px-3">{{ data.plateNumber }}</td>
              <td class="fs-6 py-2 px-3">{{ data.citationId }}</td>
              <td class="fs-6 py-2 px-3">
                {{ data.createdDate | date : "MM/dd/yyyy" }}
              </td>
              <td class="fs-6 py-2 px-3">
                {{ mapOperation(data?.operationId) }}
              </td>
              <td class="fs-6 py-2 px-3">
                <span
                  [ngClass]="
                    data?.statusMessage === 'Succeeded'
                      ? 'status-success'
                      : 'status-fail'
                  "
                  >{{ data?.statusMessage }}</span
                >
              </td>
              <td class="fs-6 py-2 px-3">{{ data.sent }}</td>
              <td class="fs-6 py-2 px-3">{{ data.errorMessage }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <mat-paginator
        class="mat-paginator-sticky"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        [length]="totalRows"
        showFirstLastButtons
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </div>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </section>
</div>
