import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CitationList } from '../_models/citationList';

import { first, map, takeUntil } from 'rxjs/operators';
import { CitationService, CitationSettledListService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { AuthorizationService } from 'src/app/_services';

interface SelectDropdownIdName {
  id: string;
  name: string;
}

@Component({
  selector: 'app-citation-settled-citations',
  templateUrl: './citation-settled-citations.component.html',
  styleUrls: ['./citation-settled-citations.component.scss']
})
export class CitationSettledCitationsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('violationSelect', { static: true }) violationSelect: MatSelect;
  @ViewChild('multipleSelect', { static: true }) multipleSelect: MatSelect;
  @ViewChild('settlementSelect', { static: true }) settlementSelect: MatSelect;

  loading = false;

  selectedOption = -1;
  settledCitationsStatics: any;
  title = 'Settled Citations ';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<CitationList>;
  filteredViolationOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  selectedSettlementChannel: string[] = [];
  filterSettlementChannel = [{ id: '', name: 'All' }, { id: 'MOTORIST', name: 'MOTORIST' }, { id: 'ENFORCEMENT_MOBILE', name: 'ENFORCEMENT_MOBILE' }, { id: 'ENFORCEMENT_WEB', name: 'ENFORCEMENT_WEB' }];

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  // isExpansionDetailRow = (i: number, row: Object) =>
  //   row.hasOwnProperty('detailRow');
  expandedElement: any;
  displayedColumns: string[] = ['index', 'citationNumber', 'createdDate', 'modifiedDate', 'licensePlate', 'amount', 'actionBy', 'violationDesc'];
  dataList: [] = [];
  totalCitations: Number = 0;
  countCitations = 0;
  totalInvoices: Number = 0;
  sortBy: string = 'id';
  sortDirection: string = 'ASC';
  settledByAll: boolean = false;
  selectedDateRange: any[] = [];
  protected _onDestroy = new Subject<void>();

  filterFromDate;
  filterToDate;
  filteredViolationData: any;
  // filtersFormCtrlEnforcerName.value
  // enforcer name  //
  filteredEnforcerData: any = [];
  /** control for the selected enforcerName */
  filtersFormCtrlEnforcerName: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  enforcerNameFilterSearchCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  filteredEnforcerOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // end enforcer name  //


  operationsList: string[] = ['Dammam', 'Al Khobar', 'AL QASIM'];
  operationValue: number = -1;

  constructor(
    private _router: Router,
    private _service: CitationSettledListService,
    private _citationService: CitationService,
    private _dialog: MatDialog,
    private location: Location,
    public authorizationService: AuthorizationService
  ) {
    this.dataSource = new MatTableDataSource<CitationList>(this.dataList);
  }
  onRadioChange(event: any): void {
    this.operationValue = event.value;
    this.loading = false;
  }

  ngOnInit(): void {
    this._loadGetEmployeesByNameOrUserNameOrId();
    this.enforcerNameFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.filteredEnforcerData.filter(item =>
            (item.name.toLowerCase().indexOf(this.enforcerNameFilterSearchCtrl.value.toLowerCase()) > -1) || (item.id == this.enforcerNameFilterSearchCtrl.value)
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredEnforcerOptions.next(
          filtered
        );
      }, error => { });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.settlementSelect.options.first.select();
    });
  }
  private _loadGetEmployeesByNameOrUserNameOrId(keyword: string = ''): void {
    this.loading = true;
    this._citationService.getEmployeesByNameOrUserNameOrId(keyword)
      .pipe(first())
      .subscribe(
        resp => {
          this.filteredEnforcerData = resp.payload.sort((a, b) => a.name > b.name ? 1 : -1);
          this.filteredEnforcerOptions.next(
            this.filteredEnforcerData
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  private _loadList(page: number, limit: number, fromDate: string, toDate: string, actionBy: string[],channels:string[], operationId: number) {
    this.loading = true;
    this._service.getList(page,limit,fromDate,toDate,actionBy,channels,operationId)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<CitationList>(this.dataList);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'citationNumber': return new Date(item['id']);
              case 'licensePlate': return item['plateNumberEn'];
              case 'violationDesc': return item['violationName'];
              default: return item[property];
            }
          };
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(' err ===== ', error);
        });
  }
  onApplyFilter() {
    this.loading = true;
    this.totalCitations = undefined;
    this.countCitations = undefined;
    this.totalInvoices = undefined;
    console.log(this.selectedSettlementChannel);
    
    if (this.settledByAll) {
      this._loadTotalSettled(this.filterFromDate, this.filterToDate,this.selectedSettlementChannel, this.operationValue);
    } else {
     let allSelectedSettle = this.filtersFormCtrlEnforcerName.value.filter(function( element ) {
        return element !== undefined;
     });
      
      this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, allSelectedSettle, this.selectedSettlementChannel,this.operationValue);
      this._loadTotal(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, allSelectedSettle, this.selectedSettlementChannel,this.operationValue);
    }

  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, this.filtersFormCtrlEnforcerName.value,this.selectedSettlementChannel, this.operationValue);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    this.sortBy = sortState.active;
    this.sortDirection = sortState.direction;
    this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, this.filtersFormCtrlEnforcerName.value,this.selectedSettlementChannel, this.operationValue);
  }

  onSelectedDateRange() {
    if (this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
    }
  }

  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  get validSearch() {
    return (this.selectedDateRange[0] && this.selectedDateRange[1] && this.filtersFormCtrlEnforcerName.value) ? true : false;
  }

  getFormatedDate(date) {
    if (!date) return ' - ';
    const d = new Date(date);
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();

    const hours = (d.getHours() < 10) ? `0${d.getHours()}` : d.getHours();
    const minutes = (d.getMinutes() < 10) ? `0${d.getMinutes()}` : d.getMinutes();
    const seconds = (d.getSeconds() < 10) ? `0${d.getSeconds()}` : d.getSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  clearEnforcer() {
    this.multipleSelect.options.forEach((data: MatOption) => data.deselect());
    this.enforcerNameFilterSearchCtrl.setValue('');
  }
  clearSettlement() {
    this.settlementSelect.options.forEach((data: MatOption) => data.deselect());
    this.selectedSettlementChannel = [];
  }

  toggleAllSelection(matSelect: MatSelect) {
    const isSelected = matSelect.options.filter((item: MatOption) => item.value == '0').map((item: MatOption) => item.selected)[0];
    if (isSelected) {
      matSelect.options.forEach((item: MatOption)   =>   item.select());

      this.settledByAll = true;
      
    } else {

      this.settledByAll = false;
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
  disableAllSelection(matSelect: MatSelect) {
    const isSelected = matSelect.options.filter((item: MatOption) => item.value == '0').map((item: MatOption) => item.selected)[0]
    if (isSelected) {
      let allCheckBox = matSelect.options.filter((item: MatOption) => item.value == '0');
      allCheckBox[0].deselect();
      this.settledByAll = false;
    }
  }

  private _loadTotal(page: number, limit: number, fromDate: string, toDate: string, actionBy: string[],channels:string[], operationId: number) {
    this.loading = true;

    this._service.getTotal(page, limit, fromDate, toDate, actionBy,channels, operationId)
      .pipe(first())
      .subscribe(
        resp => {
          this.totalCitations = resp.payload.fees || 0;
          this.countCitations = resp.payload.totalSettlements || 0;
          this.totalInvoices = resp.payload.totalInvoices || 0;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  private _loadTotalSettled(fromDate: string, toDate: string,channels:string[], operationId: number) {
    this.loading = true;

    this._service.getTotalSettled(fromDate, toDate,channels, operationId)
      .subscribe(
        resp => {
          this.dataList = [];
          this.loading = false;
          this.settledCitationsStatics = resp.payload;
          this.setAllSettledCitationsTotalAndCount();
        },
        error => {
          this.loading = false;
          console.log(' err ===== ', error);
        });
  }

  private setAllSettledCitationsTotalAndCount() {
    this.totalCitations = 0;
    this.countCitations = 0;
    this.totalInvoices = 0;
    for (let i = 0; i < this.settledCitationsStatics.length; i++) {
      this.totalCitations = this.totalCitations + this.settledCitationsStatics[i].totalFees;
      this.countCitations = this.countCitations + this.settledCitationsStatics[i].totalSettlements;
      this.totalInvoices = this.totalInvoices + this.settledCitationsStatics[i].totalInvoices;
    }
  }

  public downloadCSV() {

    this.loading = true;
    if (this.totalRows > 40000) {
      const dialogRef = this._dialog.open(DialogConfirmation, {
        panelClass: 'custom-mat-show-image-dialog-container',
        width: '468px',
        data: {
          title: { title: "Attention", color: "#D93636" },
          body: "Too Much Records",
          btnConfirmation: { hide: true, title: "Confirm", color: "#D93636" },
          btnClose: { title: "close" },
          comment: false
        }
      });
      dialogRef.afterClosed().subscribe(result => { });
      return false;
    }

    this.loading = true;
    let allSelectedSettle = this.filtersFormCtrlEnforcerName.value.filter(function( element ) {
      return element !== undefined;
   });
   
    const params = {
      offset: this.currentPage,
      limit: this.pageSize,
      fromDate: this.filterFromDate,
      toDate: this.filterToDate,
      actionByMany: [...allSelectedSettle],
      channels:this.selectedSettlementChannel,
      operationId: this.operationValue
    };
    
    const { actionByMany, ..._paramWithSettled } = params;
    
    
    this._service.downloadCSV(this.settledByAll ? _paramWithSettled : params).pipe(first())
      .subscribe(
        resp => {
          this.loading = false;
          if (resp !== undefined) {
            const hiddenElement = document.createElement('a');
            hiddenElement.setAttribute("href", `data:text/csv;charset=utf-8,%EF%BB%BF ${encodeURIComponent(resp)}`);
            hiddenElement.setAttribute("download", 'citations-settled.csv');
            hiddenElement.style.visibility = 'hidden';
            hiddenElement.click();
          }

        },
        error => {
          this.loading = false;
        });
  }

  goBack() {
    this.location.back();
  }
}
